import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UILayout } from 'bora-material-ui'
import { __, always, cond, gte, lt, T as trueFn } from 'ramda'

import messages from '../../../../../../../consts/messages'
import EditModeButton from './EditModeButton'

import RowInfo from './RowInfo'
import { selectors } from '../../../../../provider'
import { connect } from 'react-redux'

const xsStyles = {
  column: { column: 'true' },
  marginBottom15: { margin: '0 0 15px 0' },
  width100ChildrenBottomStyles: { width: '100%', childrenStyle: { margin: '0 0 10px 0' } },
  columnChildrenBottomStyles: { column: 'true', childrenStyle: { margin: '0 0 10px 0' } },
  width100WithoutPaddingAndBorders: { width: '100%', border: '0!important', padding: '0!important' },
  vehicleInfo: { column: true, width: '100%' },
}

const VehicleData = (
  {
    lockEdit,
    editMode,
    onEditCar,
    vehicleNumber,
    carWidth,
    carHeight,
    carWeight,
    carLength,
    isLocal,
    vehicleFromMnt,
    vehicleType,
    shouldReplaceVehicleWidth = false,
  },
  { intl }
) => {
  const T = intl.formatMessage

  const getWidthLabel = cond([
    [lt(__, 200), always(T(messages.carWidthS))],
    [gte(__, 250), always(T(shouldReplaceVehicleWidth ? messages.carWidthLSVI : messages.carWidthL))],
    [trueFn, always(T(shouldReplaceVehicleWidth ? messages.carWidthMSVI : messages.carWidthM))],
  ])

  const carWidthFull = vehicleFromMnt ? `${parseInt(carWidth, 10) / 100} m` : getWidthLabel(carWidth)

  const carHeightWithText = parseInt(carHeight, 10) < 400 ? T(messages.carHeightS) : T(messages.carHeightL)

  const carHeightFull = vehicleFromMnt ? `${parseInt(carHeight, 10) / 100} m` : carHeightWithText

  const carWeightFull =
    parseInt(carWeight, 10) < 1000 && !vehicleFromMnt ? T(messages.carWeightS) : `${parseInt(carWeight, 10) / 1000} t`

  return (
    <UILayout
      data-test="vehicle-trailer-wrap"
      column
      flex-end
      margin="0 0 1rem 0"
      // xs={xsStyles.width100ChildrenBottomStyles}
    >
      <Fragment>
        <UILayout data-test="vehicle-info" position="relative" row sm={xsStyles.vehicleInfo}>
          <EditModeButton lock={lockEdit} display={editMode} onEdit={onEditCar} />

          <UILayout
            column
            j-flex-end
            flex-end
            childrenStyle={xsStyles.marginBottom15}
            width={isLocal ? '50%' : '100%'}
            sm={xsStyles.width100ChildrenBottomStyles}
          >
            <RowInfo
              testId="vehicle-license-number"
              label={T(messages[`${vehicleType}RegNr`])}
              value={vehicleNumber}
              editMode={editMode}
            />
            <RowInfo
              testId="vehicle-length"
              label={T(messages.carLength)}
              value={isLocal ? carLength : `${Number(carLength) / 100} m`}
              editMode={editMode}
            />
            <RowInfo
              testId="vehicle-height"
              label={T(messages.carHeight)}
              value={isLocal ? carHeight : carHeightFull}
              editMode={editMode}
            />
            <div display-if={!isLocal}>
              <RowInfo
                testId="vehicle-width"
                style={xsStyles.marginBottom15}
                label={T(messages.carWidth)}
                value={isLocal ? carWidth : carWidthFull}
                editMode={editMode}
              />
              <RowInfo
                testId="vehicle-weight"
                label={T(messages.carWeight)}
                value={isLocal ? carWeight : carWeightFull}
                editMode={editMode}
              />
            </div>
          </UILayout>
          <UILayout
            display-if={isLocal}
            style={xsStyles.marginBottom15}
            column
            j-flex-end
            flex-end
            childrenStyle={xsStyles.marginBottom15}
            width="50%"
            sm={xsStyles.width100ChildrenBottomStyles}
          >
            <RowInfo label={T(messages.carWidth)} value={isLocal ? carWidth : carWidthFull} editMode={editMode} />
            <RowInfo label={T(messages.carWeight)} value={isLocal ? carWeight : carWeightFull} editMode={editMode} />
          </UILayout>
        </UILayout>
      </Fragment>
    </UILayout>
  )
}

VehicleData.propTypes = {
  editMode: PropTypes.any,
  lockEdit: PropTypes.any,
  onEditCar: PropTypes.func.isRequired,
  onEditTrailer: PropTypes.func.isRequired,
  vehicleNumber: PropTypes.any.isRequired,
  carWidth: PropTypes.any.isRequired,
  carHeight: PropTypes.any.isRequired,
  carWeight: PropTypes.any.isRequired,
  carLength: PropTypes.any.isRequired,
  trailerNr: PropTypes.any.isRequired,
  trailerWeight: PropTypes.any.isRequired,
  isLocal: PropTypes.bool,
  vehicleFromMnt: PropTypes.bool.isRequired,
  vehicleType: PropTypes.string,
}

VehicleData.defaultProps = {
  editMode: false,
  lockEdit: false,
  isLocal: false,
  vehicleType: 'car',
}

VehicleData.contextTypes = {
  intl: PropTypes.any,
}

const mapStateToProps = (state) => {
  const sailDate = selectors.getSailDate(state)
  const tripDate = new Date(sailDate)
  const { code = '' } = selectors.getFirstSelectedSailPackage(state)
  // const outOfBookingBoundary = getBookingConditionsForRoute(state)
  // const isLaaksaare = getSelectedSailPackageFirstRoute(state).startsWith('LAA-')

  const shouldReplaceVehicleWidth = !(code.includes('TRI') && tripDate < Number(new Date('2022/03/01')))
  return {
    shouldReplaceVehicleWidth,
  }
}

export default connect(mapStateToProps)(VehicleData)
