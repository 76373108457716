import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIButton, UIText, UIIcon } from 'bora-material-ui'

import messages from '../../../../../consts/messages'

const xsStyles = {
  button: { margin: '0px' },
}

const VehicleEditManuallyButton = ({ enabled, onClick }, { muiTheme }) => (
  <UIButton
    onClick={onClick}
    disabled={!enabled}
    height="auto"
    width="100%"
    borderRadius="2px"
    margin={'0px 0px 10px 0px'}
    xs={xsStyles.button}
  >
    <UILayout
      data-test="vehicle-edit-manually-button"
      row
      j-flex-center
      minHeight="37px"
      height="100%"
      width="100%"
      bgColor="transparent"
      center
    >
      <UIText
        size="19px"
        color="white"
        padding="13px 15px"
        textTransform="uppercase"
        letterSpacing="0.6px"
        lineHeight="1.16"
        translate={messages.changeWeightManually}
        font={muiTheme.secondFontFamilyDem}
      />
      <UIIcon width="14px" height="14px" type="iconPencil" fill="#fff" margin="0 5px 0 0" {...muiTheme.ids.iconEdit} />
    </UILayout>
  </UIButton>
)

VehicleEditManuallyButton.contextTypes = {
  muiTheme: PropTypes.object,
}

VehicleEditManuallyButton.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default VehicleEditManuallyButton
